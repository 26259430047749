<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-3">Prelab 5: Question 2</h2>

      <p class="mb-3">
        At
        <stemble-latex content="$20.0\,^\circ\text{C,}$" />
        a student studying the catalase-catalyzed decomposition of hydrogen peroxide obtained an
        initial rate of formation of oxygen of
        <number-value :value="rateT1" unit="\text{mL/s.}" />
        At
        <number-value :value="T2" unit="^\circ\text{C,}" />
        and
        <stemble-latex content="$37.0\,^\circ\text{C,}$" />
        the student obtained initial rates of
        <latex-number :number="rateT2.toFixed(3)" unit="\text{mL/s}" />
        and
        <latex-number :number="rateT3.toFixed(3)" unit="\text{mL/s,}" />
        respectively. What was the value of the activation energy that the student obtained?
      </p>

      <calculation-input
        v-model="inputs.Ea"
        class="mb-0"
        prepend-text="$\text{E}_\text{a}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'UOttawaPrelab5Q2',
  components: {
    LatexNumber,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Ea: null,
      },
    };
  },
  computed: {
    rateT1() {
      return this.taskState.getValueBySymbol('rateT1').content;
    },
    T2() {
      return this.taskState.getValueBySymbol('T2').content;
    },
    Ea() {
      return this.taskState.getValueBySymbol('Ea').content;
    },
    rateT2() {
      return (
        this.rateT1.toFloat() *
        Math.exp(
          (-(this.Ea.toFloat() * 1000) / 8.314) *
            (1 / (this.T2.toFloat() + 273.15) - 1 / (20.0 + 273.15))
        )
      );
    },
    rateT3() {
      return (
        this.rateT1.toFloat() *
        Math.exp(
          (-(this.Ea.toFloat() * 1000) / 8.314) * (1 / (37.0 + 273.15) - 1 / (20.0 + 273.15))
        )
      );
    },
  },
};
</script>
